import React, { useEffect, useState } from 'react'
import { MdCloudUpload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import axios from '../../../../utils/axios'
import { setQuestionDesc } from '../../../../slice/questionDesc'
import TextEditor from '../textEditor'
import { setStatusQuestion } from '../../../../slice/status'
import AudioForm from '../../form/audio'

const DescriptionWriting = () => {
  const dispatch = useDispatch()
  const questionDesc = useSelector((s) => s.questionDesc)
  const [description, setDescription] = useState(questionDesc.description)
  const [writing1, setWriting1] = useState(questionDesc.text_writing1)
  const [writing2, setWriting2] = useState(questionDesc.text_writing1)
  const [listSubject, setListSubject] = useState([])
  const [selectedClassCategories, setSelectedClassCategories] =
    useState('ielts')
  const [selectedFile, setSelectedFile] = useState('')
  const [textaudio, setTextaudio] = useState(questionDesc.text_audio)

  const onChangeClassCategories = (event) => {
    setSelectedClassCategories(event.target.value)
  }

  const changeHandler = (e) => {
    const file = e.target.files[0]
    if (file) setSelectedFile(file)
  }

  useEffect(() => {
    console.log('first writing')
    dispatch(
      setQuestionDesc({
        audio_name: '',
        listening: '',
        description: '',
        duration: '',
        id: '',
        subject: '',
        test_name: '',
        audio_text: '',
        updatedAt: '',
      })
    )
    setListSubject([])
    fetchSubject()
  }, [])

  const submit = (e) => {
    console.log('subj:', questionDesc.subject)
    e.preventDefault()

    const data = new FormData(e.target)

    data.append('description', description)
    data.append('text_writing1', writing1)
    data.append('text_writing2', writing2)
    data.append('category', 'writing')
    if (selectedFile) {
      data.append('files', selectedFile)
    }

    // update jika sudah disubmit dan mengklik tombol lagi
    if (questionDesc.id) {
      // data.append("id", questionDesc.id);
      axios
        // .put("/questions/description", data)
        .put(
          `/questions/${questionDesc.subject}/writing/${selectedClassCategories}/description`,
          data
        )
        .then((res) => {
          // TODO: ui
          alert('Sukses update')
          dispatch(setQuestionDesc(res.data.result))
        })
        .catch((err) => {
          alert('Gagal update')
          console.error(err.message)
        })
    } else {
      axios
        // .post("/questions/description", data)
        .post(
          `/questions/${questionDesc.subject}/writing/${selectedClassCategories}/description`,
          data
        )
        .then((res) => {
          // TODO: ui
          alert('Sukses ditambahkan')
          dispatch(setQuestionDesc(res.data.result))
        })
        .catch((err) => {
          alert('Gagal ditambahkan')
          console.error(err.message)
        })
    }
  }

  const selectSubject = (event) => {
    axios
      .get(
        `/questions/${event.target.value}/writing/${selectedClassCategories}/description`
      )
      .then((res) => {
        console.log('tes:', res)
        // TODO: ui
        if (res.data.result) {
          console.log('questions desc:', questionDesc)
          console.log('get subject:', res.data.result)
          const myarr = res.data.result.text_reading
            ? res.data.result.text_reading.split(',')
            : []
          dispatch(setQuestionDesc(res.data.result))
          setDescription(res.data.result.description)
          setWriting1(res.data.result.text_writing1)
          setWriting2(res.data.result.text_writing2)
          dispatch(setStatusQuestion(false))
        } else {
          dispatch(
            setQuestionDesc({
              audio_name: '',
              listening: '',
              description: '',
              description: '',
              duration: '',
              id: '',
              subject: event.target.value,
              test_name: '',
              text_writing1: '',
              text_writing2: '',
              updatedAt: '',
            })
          )
        }
      })
      .catch((err) => {
        alert(event.target.value)
        alert('Gagal get subject')
        dispatch(
          setQuestionDesc({
            audio_name: '',
            listening: '',
            description: '',
            duration: '',
            id: '',
            subject: event.target.value,
            test_name: '',
            text_writing1: '',
            text_writing2: '',
            updatedAt: '',
          })
        )
        setDescription('')
        console.error(err.message)
      })
  }

  const fetchSubject = () => {
    let subject = []
    axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results
        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.value = val.name
          val.name = capitalizedWords
          subject.push(val)
        })
        setListSubject(subject)
      })
      .catch((err) => {
        alert('Gagal get list subject')
        console.error(err.message)
      })
  }

  const listClassCategories = [
    {
      id: 1,
      name: 'IELTS',
      value: 'ielts',
    },
    {
      id: 2,
      name: 'TOEFL IBT',
      value: 'tibt',
    },
    {
      id: 3,
      name: 'English for Business',
      value: 'efb',
    },
  ]

  return (
    <form className="max-w-full" onSubmit={submit}>
      {questionDesc.id && (
        <div className="flex flex-col text-grey-400 mb-5">
          <label>Question Description ID</label>
          <input
            type="text"
            className="w-full bg-[#eee] rounded-lg mt-2 p-2 border"
            value={questionDesc.id}
            disabled={true}
          />
        </div>
      )}

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Subject</label>
        <select
          className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="subject"
          required
          defaultValue={questionDesc?.subject}
          key={questionDesc?.subject}
          onChange={selectSubject}
        >
          <option>Choose</option>
          {listSubject &&
            listSubject.map((val, idx) => (
              <option key={idx} value={val.value}>
                {val.name}
              </option>
            ))}
        </select>
      </div>

      <div className="flex flex-col text-grey-400 mt-5 mb-5">
        <label>Class Category</label>
        <div className="flex items-center mt-2">
          <select
            className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
            name="typeclass"
            required
            value={selectedClassCategories}
            onChange={onChangeClassCategories}
          >
            <option>Choose</option>
            {listClassCategories &&
              listClassCategories.map((val, idx) => (
                <option key={idx} value={val.value}>
                  {val.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      {selectedClassCategories === 'tibt' && (
        <AudioForm
          id="audio-1"
          title="Audio"
          audioName={
            selectedFile?.name ? selectedFile.name : questionDesc.audio_name
          }
          onChangeAudio={changeHandler}
          audioText={textaudio}
          setAudioText={setTextaudio}
        />
      )}

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Test Name</label>
        <input
          type="text"
          className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="test_name"
          placeholder="enter test name"
          defaultValue={questionDesc?.test_name}
          key={questionDesc?.test_name}
        />
      </div>

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Duration (hh:mm:ss)</label>
        <input
          type="text"
          className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="duration"
          title="hh:mm:ss"
          pattern="\d\d:\d\d:\d\d"
          placeholder="duration in hh:mm:ss format"
          defaultValue={questionDesc?.duration}
          key={questionDesc?.duration}
        />
      </div>
      <div className="flex flex-col text-grey-400 mt-5">
        <label>Description/Instruction</label>
        <TextEditor value={description} setValue={setDescription} idx={1} />
      </div>
      <div className="flex flex-col text-grey-400 mt-5">
        <label>Writing Task 1</label>
        <TextEditor value={writing1} setValue={setWriting1} idx={2} />
      </div>

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Writing Task 2</label>
        <TextEditor value={writing2} setValue={setWriting2} idx={3} />
      </div>
      <div className="flex justify-end">
        <button
          className={`bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600 mt-10`}
          type="submit"
        >
          <span className="px-5">{questionDesc.id ? 'Update' : 'Submit'}</span>
        </button>
      </div>
    </form>
  )
}

export default DescriptionWriting
