import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  deleteProduct,
  fetchAllProducts,
  fetchProducts,
  postProducts,
} from '../services/apiPlacementTest'
import { toast } from 'react-toastify'

export const useFetchAllProducts = ({ category }) => {
  const query = useQuery({
    queryKey: ['placementTestAllProduts', { category }],
    queryFn: () => fetchAllProducts({ category }),
  })

  return query
}

export const useFetchProducts = ({ category, level }) => {
  const query = useQuery({
    queryKey: ['placementTestProduts', { category, level }],
    queryFn: () => fetchProducts({ category, level }),
  })

  return query
}

export const usePostProducts = () => {
  const queryClient = useQueryClient()

  const query = useMutation({
    mutationFn: postProducts,
    onError: (err) => toast.error(err.message),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['placementTestAllProduts'],
      })
      toast.success(data.res.data.message)
    },
  })

  return query
}

export const useDeleteProduct = ({ onSuccess }) => {
  const queryClient = useQueryClient()

  const query = useMutation({
    mutationFn: deleteProduct,
    onError: (err) => toast.error(err.message),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['placementTestAllProduts'],
      })
      toast.success(data.res.data.message)
      onSuccess()
    },
  })

  return query
}
