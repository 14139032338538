import React, { useEffect, useState } from 'react'
import { MdCloudUpload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import axios from '../../../../utils/axios'
import { setQuestionDesc } from '../../../../slice/questionDesc'
import TextEditor from '../textEditor'
import { setStatusQuestion } from '../../../../slice/status'
import AlertPopup from '../../../Popup/Alert'
import { FaPlus } from 'react-icons/fa'
import FormAddSubject from '../../../FormAddSubject'
import AudioForm from '../../form/audio'

const DescriptionPlacementTest = ({
  selectedClassCategories,
  onChangeClassCategories,
}) => {
  const dispatch = useDispatch()
  const questionDesc = useSelector((s) => s.questionDesc)

  const listClassCategories = [
    {
      id: 1,
      name: 'IELTS',
      value: 'ielts',
    },
    {
      id: 2,
      name: 'TOEFL IBT',
      value: 'tibt',
    },
    {
      id: 3,
      name: 'English for Business',
      value: 'efb',
    },
  ]

  useEffect(() => {
    if (selectedClassCategories) {
      dispatch(
        setQuestionDesc({
          id: `placement-test-${selectedClassCategories}`,
        })
      )
    }
  }, [dispatch, selectedClassCategories])

  return (
    <>
      <div className="max-w-full">
        {questionDesc.id && (
          <div className="flex flex-col text-grey-400 mb-5">
            <label>Question Description ID</label>
            <input
              type="text"
              className="w-full bg-[#eee] rounded-lg mt-2 p-2 border"
              value={questionDesc.id}
              disabled={true}
            />
          </div>
        )}

        <div className="flex flex-col text-grey-400 mt-5 mb-5">
          <label>Class Category</label>
          <div className="flex items-center mt-2">
            <select
              className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
              name="classCategory"
              required
              value={selectedClassCategories}
              onChange={onChangeClassCategories}
            >
              <option>Choose</option>
              {listClassCategories &&
                listClassCategories.map((val, idx) => (
                  <option key={idx} value={val.value}>
                    {val.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export default DescriptionPlacementTest
