export const getLevelDescription = (level) => {
  switch (level) {
    case 'beginner':
      return 'Beginner(1-10)'
    case 'elementary':
      return 'Elementary (11-15)'
    case 'intermediate':
      return 'Intermediate (16-18)'
    case 'high_intermediate':
      return 'Intermediate (19-20)'
    case 'upper_intermediate':
      return 'Upper Intermediate (21-25)'
    default:
      return 'Unknown level'
  }
}
