import { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import Result from './Result'
import TestStats from './TestStats'
import SelectSubject from './SelectSubject'
import Tab from './Tab'
import SelectClassCategory from './SelectClassCategory'
import {
  convertScoreToBandScore,
  getProficiencyLevel,
} from '../../../utils/scoreIndicators'
import moment from 'moment/moment'
import QuestionText from './QuestionText'

export default function ReportReading({ testName, userId }) {
  const [questions, setQuestions] = useState([])
  const [answer, setAnswers] = useState('')
  const [selectedClassCategories, setSelectedClassCategories] =
    useState('ielts')
  const [selectedSubject, setSelectedSubject] = useState('pre-test')

  const handleChangeSelectedClassCategories = (value) => {
    setSelectedClassCategories(value)
    fetchQuestions(selectedSubject, value)
  }

  useEffect(() => {
    handleSubject('pre-test')
  }, [])

  const modifyQuestionsNumber = (data) => {
    let curNumber = 1
    let copyQuestions = [...data]

    copyQuestions = copyQuestions
      .sort((a, b) =>
        selectedClassCategories === 'ielts'
          ? a.number - b.number
          : a.sort - b.sort
      )
      .map((val) => {
        const newVal = { ...val, number: curNumber }

        if (Number(val.type) <= 8) {
          curNumber += 1 + (val.multi || 0)
        }

        return newVal
      })
      .filter((val) => Number(val.type) <= 8)

    return copyQuestions
  }

  const fetchQuestions = async (subject, classCategory) => {
    let dataQuestion = []
    let dataAnswer = []
    setQuestions([])
    try {
      const questionsResponse1 = await axios.get(
        `/questions/${subject}/reading/${classCategory}/1`
      )
      const questionsData1 = questionsResponse1.data
      let questionsResponse2
      let questionsData2
      let questionsResponse3
      let questionsData3

      if (classCategory === 'tibt') {
        questionsResponse2 = await axios.get(
          `/questions/${subject}/listening/${classCategory}/2`
        )
        questionsData2 = questionsResponse2.data
        questionsResponse3 = await axios.get(
          `/questions/${subject}/listening/${classCategory}/3`
        )
        questionsData3 = questionsResponse3.data
      }

      if (classCategory === 'tibt') {
        dataQuestion = [
          ...questionsData1.results[1],
          ...questionsData2.results[1],
          ...questionsData3.results[1],
        ]
      } else {
        dataQuestion = [...questionsData1.results[1]]
      }

      dataQuestion = modifyQuestionsNumber(dataQuestion)

      await axios
        .get(`/answers/${subject}/reading/${selectedClassCategories}/${userId}`)
        .then((res) => {
          if (res.data.result) {
            dataAnswer = res.data.result.answers
          }
        })

      dataQuestion.map((question) => {
        const findById = dataAnswer.findIndex(
          (answer) => answer.questionId === question.id
        )

        if (findById !== -1) {
          question.userAnswer = dataAnswer[findById].answer
        } else {
          question.userAnswer = ''
        }

        return question
      })

      setQuestions(dataQuestion)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubject = async (value) => {
    fetchQuestions(value, selectedClassCategories)
    setSelectedSubject(value)
    await axios
      .get(`/answers/${value}/reading/${selectedClassCategories}/${userId}`)
      .then((res) => {
        setAnswers(res.data.result)
      })
      .catch((err) => {
        setAnswers([])
        console.log(err)
      })
  }

  const calculateScore = () => {
    let correct = 0
    let incorrect = 0

    if (!questions.find((question) => question.userAnswer !== '')) {
      return {
        correct,
        incorrect,
      }
    }

    const isValidJson = (jsonString) => {
      try {
        JSON.parse(jsonString)
        return true
      } catch {
        return false
      }
    }

    questions
      ?.filter((question) => Number(question.type) <= 8)
      .forEach((question) => {
        let answers
        if (Number(question.type) === 2) {
          answers = isValidJson(question.answer)
            ? JSON.parse(question.answer)
            : question.answer
          const choices = isValidJson(question.choices)
            ? JSON.parse(question.choices)
            : question.choices
          if (Array.isArray(answers)) {
            answers = choices.filter((answer, index) => {
              if (answers[index]) {
                return answer
              }
            })
          }
        } else {
          answers = isValidJson(question.answer)
            ? JSON.parse(question.answer)
            : question.answer
        }
        const userAnswers = isValidJson(question.userAnswer)
          ? JSON.parse(question.userAnswer)
          : question.userAnswer

        const lowercaseAnswers = Array.isArray(answers)
          ? answers.map((answer) =>
              Array.isArray(answer)
                ? answer.map((item) => item.toLowerCase())
                : answer.toLowerCase()
            )
          : answers?.toString().toLowerCase()

        const lowercaseUserAnswers = Array.isArray(userAnswers)
          ? userAnswers.map((userAnswer) =>
              Array.isArray(userAnswer)
                ? userAnswer.map((item) => item.toLowerCase())
                : userAnswer.toLowerCase()
            )
          : userAnswers?.toString().toLowerCase()

        if (Array.isArray(lowercaseAnswers)) {
          lowercaseAnswers.forEach((item, index) => {
            if (Array.isArray(item) && Array.isArray(lowercaseUserAnswers)) {
              if (item.includes(lowercaseUserAnswers[index]?.trim())) {
                correct += 1
              } else {
                incorrect -= 1
              }
            } else if (
              Array.isArray(item) &&
              typeof lowercaseUserAnswers === 'string'
            ) {
              if (item.includes(lowercaseUserAnswers?.trim())) {
                correct += 1
              } else {
                incorrect -= 1
              }
            } else {
              if (item === lowercaseUserAnswers[index]?.trim()) {
                correct += 1
              } else {
                incorrect -= 1
              }
            }
          })
        } else {
          if (lowercaseUserAnswers?.trim() === lowercaseAnswers) {
            correct += 1
          } else {
            incorrect -= 1
          }
        }
      })

    return {
      correct,
      incorrect,
    }
  }

  const { correct: correctQuestion, incorrect: incorrectQuestion } =
    calculateScore()

  const durationTakeTest = ({ createdAt, updatedAt }) => {
    const diffMilliseconds = moment(updatedAt).diff(moment(createdAt))

    const duration = moment.duration(diffMilliseconds)

    return moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
  }

  const finalScore = () => {
    if (selectedClassCategories === 'ielts') {
      return convertScoreToBandScore(correctQuestion)
    }

    if (selectedClassCategories === 'tibt') {
      return `${correctQuestion}/${
        correctQuestion + Math.abs(incorrectQuestion)
      }`
    }
  }

  return (
    <div className="flex flex-col space-y-4 py-12 px-5">
      <div className="flex space-x-12 justify-between">
        <div className="flex flex-col space-y-10 grow">
          <div className="flex space-x-10">
            <div className="w-1/4">
              <SelectSubject onChange={(value) => handleSubject(value)} />
            </div>
            <div className="w-1/4">
              <SelectClassCategory
                onChange={(value) => handleChangeSelectedClassCategories(value)}
                value={selectedClassCategories}
              />
            </div>
          </div>
          <Tab />
        </div>
        {questions.find((question) => question.userAnswer !== '') ? (
          <TestStats
            totalScore={finalScore()}
            timeTaken={durationTakeTest({
              createdAt: answer.created_at,
              updatedAt: answer.updated_at,
            })}
            correct={correctQuestion}
            incorrect={Math.abs(incorrectQuestion)}
            result={
              selectedClassCategories === 'tibt' &&
              getProficiencyLevel('reading', correctQuestion)
            }
          />
        ) : (
          <></>
        )}
      </div>
      <div className="flex space-x-8">
        <div className="flex-1">
          <QuestionText
            subject={selectedSubject}
            category="reading"
            classCategory={selectedClassCategories}
          />
        </div>
        <div className="flex-1">
          {questions.find((question) => question.userAnswer !== '') ? (
            <Result questions={questions} />
          ) : (
            <label>No Data</label>
          )}
        </div>
      </div>
    </div>
  )
}
