import React, { useEffect, useState } from 'react'
import TextEditor from '../../widgets/textEditor'
import { Icon } from '@iconify/react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import { FaPlus } from 'react-icons/fa'
import FormAddTopicArea from '../../../FormAddTopicArea'
import axios from '../../../../utils/axios'
import { toast } from 'react-toastify'

import FormAddOverviewAnalysis from '../../../FormAddOverviewAnalysis'
import { Input, Typography } from '@material-tailwind/react'
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import RichTextEditor from '../../../TextEditor/Editor'
import { convertToSlateFormat } from '../../../TextEditor/utils/SlateUtilityFunctions'
import AsciiMathParser from 'asciimath2tex'
import { InlineMath } from 'react-katex'

const config = {
  loader: { load: ['input/asciimath'] },
}

const AnswerQuestionBuilder = ({ idx, containerId, classCategory }) => {
  const [selectedOverviewAnalysis, setSelectedoverviewAnalysis] = useState('')
  const [selectedTopicArea, setSelectedTopicArea] = useState('')
  const [topicArea, setTopicArea] = useState([])
  const [overviewAnalysis, setOverviewAnalysis] = useState([])
  const [modalFormTopicArea, setModalFormTopicArea] = useState(false)
  const [modalFormoverviewAnalysis, setModalFormoverviewAnalysis] =
    useState(false)
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)
  const {
    point = 0,
    answer = [[]],
    question,
    number,
    description,
    explanation,
  } = data
  const descriptionSlate = convertToSlateFormat(description)
  const questionSlate = convertToSlateFormat(question)
  const explanationSlate = convertToSlateFormat(explanation)
  const Asciiparser = new AsciiMathParser()

  const selectTopicArea = (event) => {
    setSelectedTopicArea(event.target.value)
    setBuilderDataById(idx, containerId, {
      ...data,
      topicId: event.target.value,
    })
  }

  const selectOverviewAnalysis = (event) => {
    setSelectedoverviewAnalysis(event.target.value)
    setBuilderDataById(idx, containerId, {
      ...data,
      analysisId: event.target.value,
    })
  }

  const cancelTopicAreaModal = () => {
    setModalFormTopicArea(false)
  }

  const cancelOverviewAnalysisModal = () => {
    setModalFormoverviewAnalysis(false)
  }

  const [totalAnswer, setTotalAnswer] = useState(
    typeof answer === 'string' ? JSON.parse(answer)[0] : answer[0]
  )

  const pointHandler = (e) => {
    setBuilderDataById(idx, containerId, { ...data, point: e.target.value })
  }

  const setQuestion = (val) => {
    setBuilderDataById(idx, containerId, { ...data, question: val })
  }

  const setDescription = (val) => {
    setBuilderDataById(idx, containerId, { ...data, description: val })
  }

  const setExplanation = (val) => {
    setBuilderDataById(idx, containerId, { ...data, explanation: val })
  }

  const setAnswer = (e, index) => {
    let newAnswer = [...totalAnswer]
    if (classCategory === 'sat') {
      newAnswer[index] = e
    } else {
      newAnswer[index] = e.target.value
    }
    setTotalAnswer(newAnswer)
    setBuilderDataById(idx, containerId, { ...data, answer: [newAnswer] })
  }

  const addOtherAnswer = () => {
    if (typeof totalAnswer === 'string') {
      setTotalAnswer([totalAnswer, ''])
    } else {
      setTotalAnswer([...totalAnswer, ''])
    }
  }

  useEffect(() => {
    const fetchTopicArea = () => {
      let topic = []
      axios
        .get('/questions/sat/topic')
        .then((res) => {
          const result = res.data.result
          result.forEach((val) => {
            let temp = val.topic.split('-')
            const capitalizedWords = temp
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
            val.value = val.topic
            val.name = capitalizedWords
            topic.push(val)
          })
          setTopicArea(topic)
          const selected = topic.find((val) => val.id === data.topicId)?.id
          if (selected) {
            setSelectedTopicArea(selected)
            setBuilderDataById(idx, containerId, {
              ...data,
              topicId: selected,
            })
          }
        })
        .catch((err) => {
          toast.error('Gagal get list topic')
        })
    }

    const fetchOverviewAnalysis = () => {
      axios
        .get('/questions/sat/analysis')
        .then((res) => {
          const result = res.data.result
          setOverviewAnalysis(result)
          const selected = result.find((val) => val.id === data.analysisId)?.id
          if (selected) {
            setSelectedoverviewAnalysis(selected)
            setBuilderDataById(idx, containerId, {
              ...data,
              analysisId: selected,
            })
          }
        })
        .catch((err) => {
          toast.error('Gagal get overview analysis')
        })
    }

    fetchTopicArea()
    fetchOverviewAnalysis()
  }, [modalFormTopicArea, modalFormoverviewAnalysis])

  return (
    <div className="border-[1px] border-gray-300 p-8 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Answer Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={() => deleteBuilder(idx, containerId, classCategory)}
        >
          Hapus
        </button>
      </header>
      <section className="mb-4 text-xs">Number : {number}</section>
      <section className="mb-4">
        <span className="block mb-2">Point Set</span>
        <input
          type="number"
          className="p-2 bg-gray-100 rounded-md"
          value={point}
          onChange={pointHandler}
        ></input>
      </section>
      {classCategory === 'sat' && (
        <section>
          <div className="flex flex-col text-grey-400 mt-5 mb-5">
            <label>Topic Area</label>
            <div className="flex items-center mt-2">
              <select
                className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
                name="topic_area"
                required
                value={selectedTopicArea}
                onChange={selectTopicArea}
              >
                <option value="">Choose</option>
                {topicArea &&
                  topicArea.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.topic}
                    </option>
                  ))}
              </select>
              <div
                className="ml-3 p-[10px] rounded-lg focus:bg-[#FAFAFA] border border-[#DEE8FF] hover:bg-[#FAFAFA] cursor-pointer"
                onClick={() => setModalFormTopicArea(true)}
              >
                <FaPlus size="1rem" />
              </div>
            </div>
          </div>
        </section>
      )}
      {classCategory === 'sat' && (
        <section>
          <div className="flex flex-col text-grey-400 mt-5 mb-5">
            <label>Overview Analysis</label>
            <div className="flex items-center mt-2">
              <select
                className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
                name="overview_analysis"
                required
                value={selectedOverviewAnalysis}
                onChange={selectOverviewAnalysis}
              >
                <option value="">Choose</option>
                {overviewAnalysis &&
                  overviewAnalysis.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.analysis_name}
                    </option>
                  ))}
              </select>
              <div
                className="ml-3 p-[10px] rounded-lg focus:bg-[#FAFAFA] border border-[#DEE8FF] hover:bg-[#FAFAFA] cursor-pointer"
                onClick={() => setModalFormoverviewAnalysis(true)}
              >
                <FaPlus size="1rem" />
              </div>
            </div>
          </div>
        </section>
      )}
      {classCategory === 'sat' && (
        <section className="mb-4">
          <span className="block mb-2">Description</span>
          <RichTextEditor
            value={descriptionSlate}
            setValue={setDescription}
            idx={`${containerId}-${idx}-description`}
          />
        </section>
      )}
      {classCategory !== 'sat' && (
        <section className="mb-4">
          <span className="block mb-2">Question</span>
          <TextEditor
            value={question}
            setValue={setQuestion}
            idx={`${containerId}-${idx}-question`}
          />
        </section>
      )}
      {classCategory === 'sat' && (
        <section className="mb-4">
          <span className="block mb-2">Question</span>
          <RichTextEditor
            value={questionSlate}
            setValue={setQuestion}
            idx={`${containerId}-${idx}-question`}
          />
        </section>
      )}
      {classCategory === 'sat' && (
        <section className="mb-4">
          <span className="block mb-2">Explanation</span>
          <RichTextEditor
            value={explanationSlate}
            setValue={setExplanation}
            idx={`${containerId}-${idx}-explanation`}
          />
        </section>
      )}
      <section className="mb-4">
        <span className="block mb-2">Set Correct Answer</span>
        <section className="w-100 bg-white flex">
          {classCategory !== 'sat' && (
            <>
              <div className="p-2 text-white self-start border-b-[2px] border-orange-500 bg-orange-500">
                Answer
              </div>
              <div className="flex flex-col w-full gap-1">
                {totalAnswer.length > 0 ? (
                  totalAnswer.map((val, index) => (
                    <input
                      type="text"
                      key={index}
                      className="border-[1px] border-gray-300 p-2"
                      placeholder="Add answer"
                      value={val}
                      onChange={(e) => setAnswer(e, index)}
                    ></input>
                  ))
                ) : (
                  <input
                    type="text"
                    className="border-[1px] border-gray-300 p-2"
                    placeholder="Add answer"
                    value={totalAnswer}
                    onChange={(e) => setAnswer(e, 0)}
                  ></input>
                )}
              </div>
            </>
          )}
          {classCategory === 'sat' && (
            <div className="flex flex-col w-full gap-2">
              {totalAnswer.length > 0 ? (
                totalAnswer.map((val, index) => (
                  <div className="flex flex-col space-y-2 p-2 border border-gray-300">
                    <div className="flex space-x-2">
                      <div className="p-2 text-white border-b-[2px] border-orange-500 bg-orange-500">
                        Answer
                      </div>
                      <Input
                        variant="static"
                        value={val}
                        onChange={(e) => setAnswer(e.target.value, index)}
                        color="black"
                        className="!text-lg !text-black !p-2"
                        placeholder="Enter answer here"
                        key={index}
                      />
                    </div>
                    <Typography
                      variant="paragraph"
                      className="flex items-center text-md"
                    >
                      <span>Answer Preview:</span>
                      <InlineMath math={Asciiparser.parse(val)} />
                    </Typography>
                  </div>
                ))
              ) : (
                <div className="flex space-x-2 p-2 border border-gray-300">
                  <div className="p-2 text-white border-b-[2px] border-orange-500 bg-orange-500">
                    Answer
                  </div>
                  <Input
                    variant="static"
                    value={totalAnswer}
                    onChange={(e) => setAnswer(e.target.value, 0)}
                    color="black"
                    className="!text-lg !text-black !p-2"
                    placeholder="Enter answer here"
                  />
                  <Typography>
                    <MathJaxContext config={config} inline dynamic>
                      <Typography
                        variant="paragraph"
                        className="flex items-center text-md"
                      >
                        <span>Answer Preview:</span>
                        <MathJax inline dynamic>{`\`${totalAnswer}\``}</MathJax>
                      </Typography>
                    </MathJaxContext>
                  </Typography>
                </div>
              )}
            </div>
          )}
        </section>
        <button
          className="px-3 py-2 rounded-md border-[1px] border-blue-600 mt-4 hover:border-blue-300"
          onClick={addOtherAnswer}
        >
          + Add Other Answer
        </button>
      </section>
      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
      {modalFormTopicArea && <FormAddTopicArea cancel={cancelTopicAreaModal} />}
      {modalFormoverviewAnalysis && (
        <FormAddOverviewAnalysis cancel={cancelOverviewAnalysisModal} />
      )}
    </div>
  )
}

export default AnswerQuestionBuilder
