import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import {
  Editable,
  withReact,
  useSlate,
  Slate,
  useSlateStatic,
  ReactEditor,
  useSelected,
  useFocused,
} from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Node,
  Range,
} from 'slate'
import { withHistory } from 'slate-history'
import EquationButton from './Equation/EquationButton'
import Equation from './Equation/Equation'
import withEquation from './plugins/withEquation'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  IconButton,
} from '@material-tailwind/react'
import {
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  Bars3CenterLeftIcon,
  Bars4Icon,
  BoldIcon,
  CodeBracketIcon,
  EllipsisHorizontalIcon,
  H1Icon,
  H2Icon,
  ItalicIcon,
  ListBulletIcon,
  NumberedListIcon,
  PhotoIcon,
  TrashIcon,
  UnderlineIcon,
} from '@heroicons/react/24/outline'
import {
  deserializer,
  serialize,
  serializer,
  decodeHTMLEntities,
} from './utils/serializer'
import 'katex/dist/katex.min.css'
import isUrl from 'is-url'
import {
  MdFormatAlignCenter,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
} from 'react-icons/md'
import Image from './Image/Image'
import { withImages } from './plugins/withImage'
import ImageButton from './Image/ImageButton'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const initialValue = [
  {
    type: 'p',
    children: [{ text: '' }],
  },
]

const refocusEditor = (editor, path) => {
  const { selection } = editor
  if (path) {
    ReactEditor.focus(editor)
    Transforms.select(editor, path)
    return
  }
  if (!selection || !selection.anchor || !selection.focus) {
    return
  }
  ReactEditor.focus(editor)
  Transforms.select(editor, selection.focus)
}

const RichTextEditor = ({ value = initialValue, setValue, syncValue }) => {
  const [realValue, setRealValue] = useState(value)

  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const editor = useMemo(
    () => withImages(withEquation(withHistory(withReact(createEditor())))),
    []
  )

  const handleEditorChange = useCallback(
    (newValue) => {
      setValue(newValue)
      setRealValue(newValue)
    },
    [setValue]
  )

  useEffect(() => {
    if (syncValue) {
      handleEditorChange(value)
    }
  }, [handleEditorChange, syncValue, value])

  return (
    <div className="slate-ui">
      <div
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(serializer(realValue)),
        }}
      />
      <Slate
        editor={editor}
        initialValue={realValue}
        onChange={handleEditorChange}
        key={JSON.stringify(realValue)}
      >
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 rounded-none border-b p-4 slate-ui"
          >
            <div className="flex space-x-2">
              <MarkButton
                format="bold"
                icon={<BoldIcon className="h-4 w-4" />}
              />
              <MarkButton
                format="italic"
                icon={<ItalicIcon className="h-4 w-4" />}
              />
              <MarkButton
                format="underline"
                icon={<UnderlineIcon className="h-4 w-4" />}
              />
              <MarkButton
                format="code"
                icon={<CodeBracketIcon className="h-4 w-4" />}
              />
              <BlockButton
                format="heading-one"
                icon={<H1Icon className="h-4 w-4" />}
              />
              <BlockButton
                format="heading-two"
                icon={<H2Icon className="h-4 w-4" />}
              />
              <BlockButton
                format="block-quote"
                icon={<EllipsisHorizontalIcon className="h-4 w-4" />}
              />
              <BlockButton
                format="numbered-list"
                icon={<NumberedListIcon className="h-4 w-4" />}
              />
              <BlockButton
                format="bulleted-list"
                icon={<ListBulletIcon className="h-4 w-4" />}
              />
              <BlockButton
                format="left"
                icon={<MdFormatAlignLeft className="h-4 w-4" />}
              />
              <BlockButton
                format="center"
                icon={<MdFormatAlignCenter className="h-4 w-4" />}
              />
              <BlockButton
                format="right"
                icon={<MdFormatAlignRight className="h-4 w-4" />}
              />
              <BlockButton
                format="justify"
                icon={<MdFormatAlignJustify className="h-4 w-4" />}
              />
              <EquationButton />
              <ImageButton />
            </div>
          </CardHeader>
          <CardBody>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Enter some text…"
              spellCheck
              autoFocus
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editor, mark)
                  }
                }

                if (event.key === 'Enter') {
                  const selectedElement = Node.descendant(
                    editor,
                    editor.selection.anchor.path.slice(0, -1)
                  )

                  if (selectedElement.type === 'equation') {
                    event.preventDefault()
                    const selectedLeaf = Node.descendant(
                      editor,
                      editor.selection.anchor.path
                    )

                    if (
                      selectedLeaf.text.length ===
                      editor.selection.anchor.offset
                    ) {
                      Transforms.insertNodes(editor, {
                        type: 'paragraph',
                        children: [{ text: '', marks: [] }],
                      })
                    } else {
                      Transforms.splitNodes(editor)
                      Transforms.setNodes(editor, { type: 'paragraph' })
                    }
                  }
                }

                if (event.key === 'Enter') {
                  const { selection } = editor
                  if (selection && Range.isCollapsed(selection)) {
                    const node = editor.children[selection.anchor.path[0]]
                    if (
                      node &&
                      ['image', 'video', 'equation'].indexOf(node.type) > -1
                    ) {
                      event.preventDefault()
                      const nextIndex = selection.anchor.path[0] + 1
                      const next = editor.children[nextIndex]
                      if (next) {
                        refocusEditor(editor, [nextIndex])
                        return
                      }
                      const emptyElement = {
                        type: 'paragraph',
                        children: [{ text: '' }],
                      }
                      Transforms.insertNodes(editor, emptyElement, {
                        at: [editor.children.length],
                      })
                      const last = Editor.last(editor, [])
                      refocusEditor(editor, last[1])
                      return
                    }
                  }
                }
              }}
              className="outline-none"
            />
          </CardBody>
        </Card>
      </Slate>
    </div>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = (props) => {
  const { element, children, attributes } = props
  const style = { textAlign: element.align }

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'equation':
      return <Equation {...props} />
    case 'image':
      return <Image {...props} />
    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <IconButton
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      size="sm"
      variant={
        isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )
          ? 'filled'
          : 'text'
      }
    >
      <div>{icon}</div>
    </IconButton>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <IconButton
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      size="sm"
      variant={isMarkActive(editor, format) ? 'filled' : 'text'}
    >
      <div>{icon}</div>
    </IconButton>
  )
}

export default RichTextEditor
