import React from 'react'

import { InlineMath, BlockMath } from 'react-katex'
import AsciiMathParser from 'asciimath2tex'
import 'katex/dist/katex.min.css'

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    {String.fromCodePoint(160)}
  </span>
)

const Equation = ({ attributes, element, children }) => {
  const { inline, math } = element
  const Asciiparser = new AsciiMathParser()
  const mathText = Asciiparser.parse(math)

  return (
    <div
      style={
        inline
          ? {
              display: 'inline-block',
              position: 'relative',
              margin: '0 .25rem',
              padding: '0 .25rem',
            }
          : {}
      }
      contentEditable={false}
    >
      <span {...attributes}>
        <span>
          {inline ? <InlineMath math={mathText} /> : <BlockMath math={math} />}
        </span>
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </span>
    </div>
  )
}

export default Equation
