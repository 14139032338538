import {
  ReactEditor,
  useSelected,
  useFocused,
  useSlateStatic,
} from 'slate-react'
import { Transforms } from 'slate'
import { TrashIcon } from '@heroicons/react/24/outline'

const useSafeSlateStatic = () => {
  try {
    return useSlateStatic()
  } catch (error) {
    // Return null if the hook is used outside of the Slate context
    return null
  }
}

const Image = ({ attributes, children, element }) => {
  const editor = useSafeSlateStatic()
  const selected = useSelected()
  const focused = useFocused()

  const handleRemoveImage = () => {
    if (editor) {
      const path = ReactEditor.findPath(editor, element)
      Transforms.removeNodes(editor, { at: path })
    }
  }

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} className="relative">
        <img
          src={element.url}
          className="block max-w-full max-h-[20em] shadow-[0_0_0_3px_#B4D5FF]"
          style={{
            boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
          }}
          alt="icon"
        />
        {editor && (
          <div
            onClick={handleRemoveImage}
            className="absolute top-1 left-1 z-10 w-8 h-8 flex justify-center items-center bg-red-700 rounded cursor-pointer"
            style={{ display: selected && focused ? 'flex' : 'none' }}
          >
            <TrashIcon className="h-5 w-5 text-white" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Image
