import { Text } from 'slate'
import { getBlock, getMarked } from './SlateUtilityFunctions.js'
import ReactDOMServer from 'react-dom/server'
import { decode } from 'html-entities'
import { jsx } from 'slate-hyperscript'

const { renderToStaticMarkup } = ReactDOMServer

export const serialize = (node) => {
  const replaceSymbol = (s) =>
    s.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&')

  if (Text.isText(node)) {
    let string = getMarked(node, node.text)
    string = renderToStaticMarkup(string)

    string = decode(replaceSymbol(string))

    return string
  }

  const children = node.children.map((n) => serialize(n)).join('')

  let block = getBlock({ children, element: node })
  block = renderToStaticMarkup(block)

  block = decode(replaceSymbol(block))

  return block
}

export const serializer = (editorValue) => {
  if (editorValue && editorValue.length > 0) {
    return editorValue.map((n) => serialize(n)).join('')
  }
}

export const deserializer = (body) => {
  const parser = new DOMParser()
  const decodedBody = decodeHTMLEntities(body)
  const document = parser.parseFromString(decodedBody, 'text/html')
  const { body: htmlBody } = document

  const extractMath = (element) => {
    const annotation = element.querySelector(
      'annotation[encoding="application/x-tex"]'
    )
    if (annotation) {
      return annotation.textContent
    }

    return ''
  }

  const deserializeElement = (element) => {
    if (element.nodeType === Node.TEXT_NODE) {
      return { text: element.textContent }
    }

    const children = Array.from(element.childNodes)
      .map(deserializeElement)
      .flat()

    switch (element.nodeName) {
      case 'BODY':
        return jsx('fragment', {}, children)
      case 'BLOCKQUOTE':
        return jsx('element', { type: 'block-quote' }, children)
      case 'UL':
        return jsx('element', { type: 'bulleted-list' }, children)
      case 'OL':
        return jsx('element', { type: 'numbered-list' }, children)
      case 'LI':
        return jsx('element', { type: 'list-item' }, children)
      case 'H1':
        return jsx('element', { type: 'heading-one' }, children)
      case 'H2':
        return jsx('element', { type: 'heading-two' }, children)
      case 'IMG': {
        const src = element.getAttribute('src')
        const alt = element.getAttribute('alt') || ''
        return jsx('element', { type: 'image', url: src, alt }, [{ text: '' }])
      }
      case 'P':
        return jsx('element', { type: 'paragraph' }, children)
      case 'STRONG':
        return children.map((child) => ({
          ...child,
          bold: true,
        }))
      case 'EM':
        return children.map((child) => ({
          ...child,
          italic: true,
        }))
      case 'BR':
        return { text: '\n' }
      // Equation still have a bug.
      case 'DIV':
        if (element.getAttribute('data-testid') === 'equation') {
          const math = extractMath(element) || ''
          const inline = element.style.display === 'inline-block'

          return jsx('element', { type: 'equation', inline, math }, children)
        }

        return children
      default:
        return children
    }
  }

  const nodes = Array.from(htmlBody.childNodes).map(deserializeElement).flat()

  return nodes.length > 0
    ? nodes
    : [{ type: 'paragraph', children: [{ text: '' }] }]
}

export const decodeHTMLEntities = (encodedString) => {
  return decode(encodedString, { level: 'html5' })
}
