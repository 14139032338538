import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import VerifyEmail from './components/VerifyEmail'
import Register from './components/Register'
import Dashboard from './pages/Dashboard'
import Todo from './pages/Todo'
import TodoSpeaking from './pages/Todo/Speaking'
import TodoWriting from './pages/Todo/Writing'
import TodoWritingResult from './pages/Todo/WritingResult'
import TodoWritingExplanations from './pages/Todo/WritingExplanations'
import ReportWriting from './pages/Report/ReportWriting'
import ReportSpeaking from './pages/Report/ReportSpeaking'
import Navbar from './components/Navbar'
import CodeEmail from './components/CodeEmail'
import Notify from './components/NotifyLogin'
import { AnimatePresence } from 'framer-motion'
import Listening from './pages/Test/listening/index'
import Speaking from './pages/Test/speaking/index'
import Data from './pages/Data/index'
import Test from './pages/Test/index'
import AddStudent from './pages/Test/addstudent/index'
import AddMentor from './pages/Test/addmentor/index'
import AddClass from './pages/Test/addclass/index'
import Report from './pages/Report/index'
import Assignment from './pages/Report/Assigmnment/index'
import Reading from './pages/Test/reading/index'
import Writing from './pages/Test/writing/index'
import 'react-toastify/dist/ReactToastify.css'
import AssignmentByClass from './pages/Report/AssignmentByClass'
import DashboardReport from './pages/Report/DashboardReport'
import ResultReport from './pages/Report/ResultReport'
import ListStudentAssignment from './pages/Report/Assigmnment/ListStudentAssignment'
import { ThemeProvider } from '@material-tailwind/react'
import { customTheme } from './utils/theme'
import EditStudent from './pages/Test/addstudent/edit'
import EditMentor from './pages/Test/addmentor/edit'
import EditClass from './pages/Test/addclass/edit'
import ListAnswers from './pages/ListAnswers'
import { ToastPortal } from '../src/components/ToastifyContainer'
import AddStudentViaClass from './pages/Test/addclass/addstudent'
import Sat from './pages/Test/sat'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import PlacementTest from './pages/Test/placement-test'
import PlacementTestAddProducts from './pages/PlacementTest/AddProducts'

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider value={customTheme}>
        <AnimatePresence>
          <ToastPortal />
          <BrowserRouter forceRefresh={true}>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/verifyemail" element={<VerifyEmail />} />
              <Route path="/codeemail" element={<CodeEmail />} />
              <Route path="/register" element={<Register />} />
              <Route path="/notify" element={<Notify />} />
              <Route
                path="/dashboard"
                element={
                  <>
                    <Navbar />
                    <Dashboard />
                  </>
                }
              />
              <Route
                path="/todo"
                element={
                  <>
                    <Navbar />
                    <Todo />
                  </>
                }
              />
              <Route
                path="/todo/speaking"
                element={
                  <>
                    <Navbar />
                    <TodoSpeaking />
                  </>
                }
              />
              <Route
                path="/todo/writing"
                element={
                  <>
                    <Navbar />
                    <TodoWriting />
                  </>
                }
              />
              <Route
                path="/todo/writing/:testName/:userId"
                element={
                  <>
                    <Navbar />
                    <TodoWritingResult />
                  </>
                }
              />
              <Route
                path="/todo/writing/:testName/:userId/explanations"
                element={
                  <>
                    <Navbar />
                    <TodoWritingExplanations />
                  </>
                }
              />
              <Route
                path="/progress"
                element={
                  <>
                    <Navbar />
                    <Dashboard />
                  </>
                }
              />
              <Route
                path="/myclass/allskills"
                element={
                  <>
                    <Navbar />
                    <Dashboard />
                  </>
                }
              />
              <Route
                path="/myclass/partial"
                element={
                  <>
                    <Navbar />
                    <Dashboard />
                  </>
                }
              />
              <Route
                path="/myreport"
                element={
                  <>
                    <Navbar />
                    <Report />
                  </>
                }
              />
              <Route
                path="/myreport/assignment/"
                element={
                  <>
                    <Navbar />
                    <Assignment />
                  </>
                }
              />
              <Route
                path="/myreport/assignment/student"
                element={
                  <>
                    <Navbar />
                    <ListStudentAssignment />
                  </>
                }
              />
              <Route
                path="/myreport/student"
                element={
                  <>
                    <Navbar />
                    <AssignmentByClass />
                  </>
                }
              />
              <Route
                path="/myreport/student/report"
                element={
                  <>
                    <Navbar />
                    <DashboardReport />
                  </>
                }
              />
              <Route
                path="/myreport/assignment/student/report"
                element={
                  <>
                    <Navbar />
                    <DashboardReport />
                  </>
                }
              />

              <Route
                path="/report/result"
                element={
                  <>
                    <Navbar />
                    <ResultReport />
                  </>
                }
              />
              <Route
                path="/report/result/explanations"
                element={
                  <>
                    <Navbar />
                    <TodoWritingExplanations />
                  </>
                }
              />

              <Route path="/test" element={<Navbar />}>
                <Route path="listening" element={<Listening />} />
                <Route path="reading" element={<Reading />} />
                <Route path="writing" element={<Writing />} />
                <Route path="sat" element={<Sat />} />
                <Route path="placement-test" element={<PlacementTest />} />
                <Route path="speaking" element={<Speaking />} />
                <Route path="additems" element={<Test />} />
                <Route path="addmentor" element={<AddMentor />} />
                <Route path="editmentor" element={<EditMentor />} />
              </Route>

              <Route
                path="/data"
                element={
                  <>
                    <Navbar />
                    <Data />
                  </>
                }
              />
              <Route path="/data" element={<Navbar />}>
                <Route path="addstudent" element={<AddStudent />} />
                <Route path="editstudent" element={<EditStudent />} />
                <Route path="addclass" element={<AddClass />} />
                <Route
                  path="addclass/:classId"
                  element={<AddStudentViaClass />}
                />
                <Route path="editclass" element={<EditClass />} />
              </Route>

              <Route
                path="/list-answers"
                element={
                  <>
                    <Navbar />
                    <ListAnswers />
                  </>
                }
              />

              <Route path="/placement-test" element={<Navbar />}>
                <Route
                  path="add-products"
                  element={<PlacementTestAddProducts />}
                />
              </Route>
            </Routes>
            <Outlet />
          </BrowserRouter>
        </AnimatePresence>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
