import { useState } from 'react'
import { useSelector } from 'react-redux'
import Drop from '../Question/widgets/drop'
import Preview from '../Question/widgets/preview'
import { usePopup } from '../Popup'
import DescriptionPlacementTest from '../Question/widgets/description/placement-test'

export const TabsPlacementTest = ({
  selectedClassCategories,
  setSelectedClassCategories,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const description = useSelector((s) => s.questionDesc)
  const { alert } = usePopup()

  const setTabIndex = (idx) => {
    // TODO: ui
    if (!description.id && idx > 0) alert('Pilih subject terlebih dahulu')
    else setActiveTabIndex(idx)
  }

  const handleClassCategories = (event) =>
    setSelectedClassCategories(event.target.value)

  const tabsData = [
    {
      label: 'Description',
      content: (
        <DescriptionPlacementTest
          onChangeClassCategories={handleClassCategories}
          selectedClassCategories={selectedClassCategories}
        />
      ),
    },
    {
      label: 'Add Items',
      content: (
        <Drop
          id="placement-test"
          classCategory={selectedClassCategories}
          testType="placement-test"
        />
      ),
    },
    {
      label: 'Preview',
      content: <Preview />,
    },
  ]

  return (
    <div>
      <div className="flex space-x-1">
        {tabsData.map((tab, idx) => {
          return (
            <button
              key={idx}
              className={`py-2 border-solid text-xs border-2 bg-[#EFF2F6] px-5 transition-colors duration-300 ${
                idx === activeTabIndex
                  ? 'bg-[#FFFFFF] border-b-0'
                  : 'border-transparen'
              }`}
              onClick={() => setTabIndex(idx)}
            >
              {tab.label}
            </button>
          )
        })}
      </div>
      <div className="py-4">
        <section>{tabsData[activeTabIndex].content}</section>
      </div>
    </div>
  )
}
