import { useRef, useState } from 'react'

import { insertEquation } from './../utils/equation.js'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import {
  Checkbox,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from '@material-tailwind/react'
import { PlusIcon, VariableIcon } from '@heroicons/react/24/outline'
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { htmlToSlate } from '@slate-serializers/html'
import { renderToStaticMarkup, renderToString } from 'react-dom/server'
import { InlineMath } from 'react-katex'
import AsciiMathParser from 'asciimath2tex'
import 'katex/dist/katex.min.css'

const config = {
  loader: { load: ['input/asciimath'] },
}

const EquationButton = () => {
  const editor = useSlate()
  const [showInput, setShowInput] = useState(false)
  const [math, setMath] = useState('')
  const [displayInline, setDisplayInline] = useState(true)
  const [selection, setSelection] = useState()
  const Asciiparser = new AsciiMathParser()
  const mathText = Asciiparser.parse(math)

  const toggleButton = () => {
    setShowInput(!showInput)
    setDisplayInline(false)
    setSelection(editor.selection)
  }

  const handleInputChange = ({ target }) => {
    if (target.type === 'checkbox') {
      setDisplayInline((prev) => !prev)
    } else {
      setMath(target.value)
    }
  }

  const handleAddEquation = () => {
    if (!math) return
    selection && Transforms.select(editor, selection)
    insertEquation(editor, math, displayInline)
    setShowInput(false)
    setMath('')
    setDisplayInline(true)
  }

  return (
    <Popover open={showInput} handler={setShowInput}>
      <PopoverHandler>
        <IconButton
          format="equation"
          size="sm"
          variant="text"
          onClick={toggleButton}
        >
          <VariableIcon className="h-4 w-4" />
        </IconButton>
      </PopoverHandler>
      <PopoverContent>
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-2">
            <Input
              type="text"
              value={math}
              onChange={handleInputChange}
              label="Formula"
            />
            <IconButton onClick={handleAddEquation}>
              <PlusIcon className="h-4 w-4" />
            </IconButton>
          </div>
          {math && (
            <div>
              <Typography
                variant="paragraph"
                className="flex items-center text-md"
              >
                <span>Preview:</span>
                <InlineMath math={mathText} />
              </Typography>
            </div>
          )}
        </div>
        <Checkbox
          type="checkbox"
          checked={displayInline}
          onChange={handleInputChange}
          label="Inline Equation"
        />
      </PopoverContent>
    </Popover>
  )
}

export default EquationButton
