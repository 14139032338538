import React, { useState } from 'react'
import axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom'
import mainLogo from '../../assets/main-logo.svg'
import { motion } from 'framer-motion'
import SubMenu from './SubMenu'
import { useSelector } from 'react-redux'

const Sidebar = () => {
  const [picture, setPicture] = useState(null)
  const account = useSelector((s) => s.account)

  const subMenusList = [
    {
      name: 'Home',
      link: 'dashboard',
      menus: [],
    },
    {
      name: 'To Do',
      link: 'todo',
      menus: [],
    },
    {
      name: 'Course',
      link: 'test',
      menus: [
        {
          name: 'Add Items',
          link: 'additems',
        },
        {
          name: 'Writing',
          link: 'writing',
        },
      ],
    },
    {
      name: 'Reports',
      link: 'myreport',
      menus: [],
    },
    {
      name: 'Data',
      link: 'data',
      menus: [],
    },
    {
      name: 'List Answers',
      link: 'list-answers',
      menus: [],
    },
    {
      name: 'Placement Test',
      link: 'placement-test',
      menus: [
        {
          name: 'Add Products',
          link: 'add-products',
        },
      ],
    },
  ]

  return (
    <div className="min-h-screen lg:px-4 lg:pb-6">
      <button className="block z-30 absolute peer px-4 pb-6 top-3 h-8 w-8  transition mb-[660px] hover:bg-white focus:bg-white lg:hidden">
        <span className="text-slate-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list w-8 h-8 m-auto"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </span>
      </button>

      <div className="border-r z-20 absolute top-[75px] -left-[600px] lg:left-0 h-full w-9/12 lg:w-72 bg-white shadow-2x1 ease-out delay-150 duration-200 peer-focus:left-0 peer:transition lg:static">
        <nav role="navigation" className="w-full p-5">
          <div className="flex justify-center">
            <div className="flex flex-col w-24 h-24 mt-5">
              <img
                src={
                  picture
                    ? picture
                    : 'https://via.placeholder.com/150.png?text=profile'
                }
                className="w-full h-full rounded-full shadow-lg"
              />
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <p className="text-lg font-bold font-sans">{account.name}</p>
          </div>
          {/* <div className="flex justify-center">
            <p className="text-sm">Admin Kobi</p>
          </div> */}
          {/* Menus */}
          <div className="flex flex-col h-full">
            <ul className="px-2.5 text-[0.9rem] py-5 flex flex-col gap-1 font-medium overflow-x-hidden scrollbar-thin">
              {/* with submenu */}
              <div className="border-y py-1 border-slate-300">
                {subMenusList?.map((menu, idx) => {
                  const depthLevel = 0
                  return (
                    <div key={idx} className="flex flex-col">
                      <SubMenu data={menu} depthLevel={depthLevel} />
                    </div>
                  )
                })}
              </div>
            </ul>
          </div>

          {/* <button className="flex mt-5 hover:text-blue-400 hover:text-white py-2 px-4 hover:border-transparent items-center w-full">
              <span className='text-sm'>Dashboard</span>
            </button>
            <div className='border-t-2 mt-2'></div>
            <button className="flex mt-2 hover:text-blue-400 hover:text-white py-2 px-4 hover:border-transparent items-center w-full">
              <span className='text-sm'>Progress Belajar</span>
            </button>
            <button className="flex mt-2 hover:text-blue-400 hover:text-white py-2 px-4 hover:border-transparent items-center w-full">
              <span className='text-sm'>My Class</span>
            </button> */}
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
