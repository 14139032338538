import { useSlateStatic } from 'slate-react'
import { IconButton } from '@material-tailwind/react'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { insertImage, isImageUrl } from '../utils/image'

const ImageButton = () => {
  const editor = useSlateStatic()
  return (
    <IconButton
      onMouseDown={(event) => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the image:')
        if (url && !isImageUrl(url)) {
          alert('URL is not an image')
          return
        }
        url && insertImage(editor, url)
      }}
      variant="text"
      size="sm"
    >
      <PhotoIcon className="h-4 w-4">image</PhotoIcon>
    </IconButton>
  )
}

export default ImageButton
